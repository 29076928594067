import { create } from "zustand";

const useExercise = create((set) => ({
  filterData: [],
  //filterData1: [],
  MainData: [],
  setData: (data) =>
    set(() => ({
      //filterData1: data.filter,
      filterData: data.filter,
      MainData: data.data,
    })),

  clearExercise: () => set((state) => ({ filterData: [], MainData: [] })),
  setMainData: (item) => set((state) => ({ MainData: item })),
}));

export default useExercise;
