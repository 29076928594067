import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import url from "../url";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Paggination from "../pagination/pagination";
import IsLogin from "./VerifyLogin";

const Tips = () => {
  let [tips, setTips] = useState([]);
  let [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [isUpdate, setisUpdate] = useState(false);

  const [category_id, setCategory_id] = useState("");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [id, setId] = useState("");
  const [category_name, setCategory_name] = useState("");
  const [ListData, setListData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [nPage, setnPage] = useState([1]);

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = tips.slice(indexOfFirstRecord, indexOfLastRecord);
    setListData(currentRecords);
  };
  //let ApiPath = ;
  const fetchTips = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_tip");
      setTips((prev) => data.data);
      console.log(data);
    } catch (er) {
      console.log("er", er);
    }
  };
  const handler = async (event) => {
    event.preventDefault();

    const category_id = event.target.value;
    const pageSize = 5;
    //setFilter(category_id);
    //alert("Category ID: " + category_id);
    try {
      let resData;

      if (category_id === "All") {
        const { data } = await axios.post(url() + "/api/user/get_tip", {
          pageSize, // Include pageSize in the request to implement pagination
        });
        resData = data.data;
        //alert("All");
      } else {
        const { data } = await axios.post(url() + "/api/user/get_tip", {
          category_id,
        });
        resData = data.data;
      }

      if (!resData || resData.length === 0) {
        setListData([]);
      } else {
        setListData(resData);
      }
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchCategory = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_category");
      setCategory((prev) => data.data);
      console.log(data);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchTipData = (item) => {
    setImage(item?.image);
    setName(item?.name);
    setDetails(item?.details);
    setId(item?.id);
    //toast.success(item?.id);

    console.log(item?.id);
  };
  const handleDeleteData = async (id) => {
    try {
      await axios.post(url() + "/api/user/del_tip", { id });
      toast.success("Exercise Deleted Successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Something Went Wrong!");
      throw error;
    }
  };

  const insertTip = async (e) => {
    e.preventDefault();
    try {
      console.log(category_id, image, name, details);
      let formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("image", image);
      formData.append("name", name);
      formData.append("details", details);
      const { data } = await axios.post(url() + "/api/user/add_tip", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(data);
      toast.success("Tip Added successfully");
      console.log("Tip Added successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Failed to insert Exercise");
      console.log("Error:", error);
    }
  };

  const insertCategory = async (event) => {
    event.preventDefault();
    try {
      console.log("Category Name:", category_name); // Check if category_name is defined
      let formData = new FormData();
      formData.append("category_name", category_name); // Ensure the key matches what backend expects
      console.log("Form Data:", formData); // Check formData before sending
      if (!category_name) {
        throw new Error("category_name is undefined");
      }
      const data = await axios.post(url() + "/api/user/add_cat", formData, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        //responseType: "text",
      });
      console.log("Response Data:", data); // Check response after the request
      toast.success("Category Added successfully");
      console.log("Category Added successfully", category_name, formData);
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Failed to insert Category");
      console.log("Error:", error);
    }

    // try {
    //   console.log("Category Name:", category_name); // Check if category_name is defined
    //   let formData = new FormData();
    //   formData.append("category_name", category_name); // Ensure the key matches what backend expects
    //   console.log("Form Data:", formData); // Check formData before sending
    //   const data = await axios.post(url() + "/api/user/add_cat", formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Accept: "application/json",
    //     },
    //   });
    //   console.log("Response Data:", data); // Check response after the request
    //   toast.success("Category Added successfully");
    //   console.log("Category Added successfully", category_name);
    //   setisUpdate(!isUpdate);
    // } catch (error) {
    //   toast.error("Failed to insert Category");
    //   console.log("Error:", error);
    // }
  };

  const handleImageUpd = (event) => {
    setImage(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    ///api/user/add_category
    console.log("event id", category_id);
    //toast.success("event id", e.id);
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("category_id", category_id);
      formData.append("image", image);
      formData.append("name", name);
      formData.append("details", details);
      const { response } = await axios.post(
        url() + `/api/user/upd_tip`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Tip updated successfully");
      console.log("Tip updated successfully");
      setisUpdate(!isUpdate);
      console.log(response, category_id);
    } catch (error) {
      //console.log("Error updating exercise:", error);
      //toast.error("Error Updating Excersize ");
      throw error;
      // Handle error
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  IsLogin();
  useEffect(() => {
    fetchTips();
    fetchCategory();

    (async function () {
      try {
        const { data } = await axios.post(url() + "/api/user/get_tip");
        const resData = data.data;
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = resData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(resData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>TIPS</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="mb-3 col-lg-2 col-md-3 col-5">
                    <label className="mb-2">Filter</label>
                    <select
                      className="form-select form-control"
                      id=""
                      aria-label="Floating label select example"
                      onChange={handler}
                    >
                      <option value="All">All </option>
                      {category?.map((cat) => (
                        <option key={cat.category_id} value={cat.category_id}>
                          {cat.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 pro-add-new px-0">
                    <Link
                      type="button"
                      className="btn add-new me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#Addcategory"
                    >
                      <i className="fa-solid fa-plus"></i> Add Category
                    </Link>
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#Addtips"
                    >
                      <i className="fa-solid fa-plus"></i> Add Tips
                    </Link>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Category</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Details</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : ListData?.length ? (
                        ListData.filter((item) => {
                          return (
                            (filter === "All" ||
                              item.category_id === parseInt(filter)) && //
                            (search.toLowerCase() === "" ||
                              item.name.toLowerCase().includes(search))
                          );
                        }).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {currentPage === 1
                                  ? index + 1
                                  : index + 1 + currentPage * 5 - 5}
                              </td>
                              <td>{item.category_id}</td>
                              <td>
                                <div className="profile-img">
                                  <img src={item.image} className="img-fluid" />
                                </div>
                              </td>
                              <td>{item.name}</td>
                              <td>{item.details}</td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => fetchTipData(item)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteData(item.id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column mt-2 justify-content-center align-items-center"
                              style={{ height: "15vh" }}
                            >
                              <img src style={{ height: "100px" }} alt="" />
                              <div>no Data found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {/* <tbody>
                      {tips
                        ?.filter((item) => {
                          return (
                            (filter === "All" ||
                              item.category_id === parseInt(filter)) && //
                            (search.toLowerCase() === "" ||
                              item.name.toLowerCase().includes(search))
                          );
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{item.category_id}</td>
                              <td>
                                <div className="profile-img">
                                  <img src={item.image} className="img-fluid" />
                                </div>
                              </td>
                              <td>{item.name}</td>
                              <td>{item.details}</td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => fetchTipData(item)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteData(item.id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody> */}
                  </table>
                </div>

                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Category Modal --> */}
        <div
          className="modal fade"
          id="Addcategory"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={insertCategory}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Add New Tip Category</label>
                      <input
                        type="text"
                        onChange={(event) =>
                          setCategory_name(event.target.value)
                        }
                        //value={category_name}
                        className="form-control"
                      />
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Add Category Modal --> */}

        {/* <!-- Add Tips Modal --> */}
        <div
          className="modal fade"
          id="Addtips"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Tips
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={insertTip}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Select Category</label>
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                        onChange={(e) => setCategory_id(e.target.value)}
                      >
                        <option selected>All</option>
                        {category.map((cat) => (
                          <option key={cat.category_id} value={cat.category_id}>
                            {cat.category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Tips Image</label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        className="form-control"
                        multiple
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Tips Title</label>
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Tips Details</label>
                      <textarea
                        name=""
                        onChange={(e) => setDetails(e.target.value)}
                        id=""
                        className="form-control"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Add Tips Modal --> */}

        {/* <!-- Edit Modal --> */}
        <div
          className="modal fade"
          id="exampleModaledit"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Tips
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Select Category</label>
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                        onChange={(e) => setCategory_id(e.target.value)}
                      >
                        <option value="All">All</option>
                        {category.map((cat) => (
                          <option key={cat.category_id} value={cat.category_id}>
                            {cat.category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Tips Image</label>
                      <div className="d-flex align-items-center">
                        <div className="col">
                          <img
                            src={image}
                            alt="Uploaded Image"
                            className="me-2"
                            style={{ maxWidth: "70px", maxHeight: "70px" }}
                          />
                        </div>
                        <div>
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter image"
                          value={image}
                          style={{ width: "100%" }}
                        /> */}
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Enter image"
                            //onChange={handleImageChange}
                            style={{ width: "100%" }}
                            onChange={(e) => setImage(e.target.files[0])}
                            //onClick={handleImageChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Tips Title</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Tips Details</label>
                      <textarea
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        name=""
                        id=""
                        className="form-control"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Edit Modal --> */}

        {<Footer />}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Tips;
