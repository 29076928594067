import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import axios from "axios";
import url from "../url";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Paggination from "../pagination/pagination";
import IsLogin from "./VerifyLogin";
const Challenge = () => {
  let [challenges, setChallenges] = useState([]);
  const [search, setSearch] = useState("");
  const [isUpdate, setisUpdate] = useState(false);
  const [ch_id, setCh_id] = useState("");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);

  const [ListData, setListData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [nPage, setnPage] = useState([1]);
  //let ApiPath = ;

  const fetchChallenge = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_challenge");
      setChallenges(data.data);
      // Corrected to setChallenges(data)
      console.log(data);
    } catch (error) {
      console.log("Error fetching challenges:", error);
    }
  };

  const fetchChallengeData = (item) => {
    setName(item?.name);
    setNumberOfDays(item?.numberOfDays);
    setDetails(item?.details);
    setImage(item?.image);
    setCh_id(item?.ch_id);

    //toast.success(item?.ch_id);

    console.log(item?.ch_id);
  };

  // useEffect(() => {

  // }, [isUpdate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("event id", ch_id);
    try {
      const formData = new FormData();
      //formData.append("id", id);
      formData.append("ch_id", ch_id);
      formData.append("name", name);
      formData.append("numberOfDays", numberOfDays);
      formData.append("details", details);
      formData.append("image", image);
      const { resposne } = await axios.post(
        url() + `/api/user/upd_challenge`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(resposne);

      toast.success("Challenge updated successfully");
      console.log("Challenge updated successfully");
      setisUpdate(!isUpdate);
      console.log(resposne, ch_id);
    } catch (error) {
      //console.log("Error updating exercise:", error);
      //toast.error("Error Updating Excersize ");
      throw error;
      // Handle error
    }
  };

  const handleDeleteData = async (ch_id) => {
    try {
      await axios.post(url() + "/api/user/del_challenge", { ch_id });
      toast.success("Exercise Deleted Successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Something Went Wrong!");
      throw error;
    }
  };

  const insertChallenge = async (event) => {
    event.preventDefault();
    try {
      console.log(name, numberOfDays, details, image);
      let formData = new FormData();

      formData.append("name", name);
      formData.append("numberOfDays", numberOfDays);
      formData.append("details", details);
      formData.append("image", image);

      const { data } = await axios.post(
        url() + "/api/user/add_challenge",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data);
      toast.success("Challenge Added successfully");
      console.log("Challenge Added successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Failed to insert Challenge");
      console.error("Error:", error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = challenges.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setListData(currentRecords);
  };
  IsLogin();
  useEffect(() => {
    fetchChallenge();
    fetchChallengeData(ch_id);

    (async function () {
      try {
        const { data } = await axios.get(url() + "/api/user/get_challenge");
        const resData = data.data;
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = resData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(resData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>CHALLENGE</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div></div>
                  <div className="mb-3 pro-add-new px-0">
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAdd"
                    >
                      <i className="fa-solid fa-plus"></i> Add Challenge
                    </Link>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>No Of Day</th>
                        <th>Challenge Details</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : ListData?.length ? (
                        ListData.filter((item) => {
                          return search.toLowerCase() === ""
                            ? item
                            : item.name.toLowerCase().includes(search) ||
                                item.numberOfDays
                                  .toLowerCase()
                                  .includes(search);
                        }).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {currentPage === 1
                                  ? index + 1
                                  : index + 1 + currentPage * 5 - 5}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.numberOfDays}</td>
                              <td>{item.details}</td>
                              <td>
                                <div className="profile-img">
                                  <img
                                    src={item.image}
                                    alt={`Challenge ${index}`}
                                    className="img-fluid"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => fetchChallengeData(item)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteData(item.ch_id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column mt-2 justify-content-center align-items-center"
                              style={{ height: "15vh" }}
                            >
                              <img src style={{ height: "100px" }} alt="" />
                              <div>no Data found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {/* <tbody>
                      {challenges
                        ?.filter((item) => {
                          return search.toLowerCase() === ""
                            ? item
                            : item.name.toLowerCase().includes(search) ||
                                item.numberOfDays
                                  .toLowerCase()
                                  .includes(search);
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.numberOfDays}</td>
                              <td>{item.details}</td>
                              <td>
                                <div className="profile-img">
                                  <img
                                    src={item.image}
                                    alt={`Challenge ${index}`}
                                    className="img-fluid"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => fetchChallengeData(item)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteData(item.ch_id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody> */}
                  </table>
                </div>
                {/* <div className="pro-add-new px-0 mb-0 pt-3">
                  <p>1 - 6 of 6</p>
                  <nav aria-label="...">
                    <ul className="pagination pagination-sm mb-0">
                      <li className="page-item active" aria-current="page">
                        <span className="page-link">1</span>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Modal --> */}
        <div
          className="modal fade"
          id="exampleModalAdd"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Challenge
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={insertChallenge}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Challenge Name</label>
                      <input
                        type="text"
                        onChange={(event) => setName(event.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">No Of Day</label>
                      <input
                        type="number"
                        onChange={(event) =>
                          setNumberOfDays(event.target.value)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Challenge Details</label>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        cols="30"
                        rows="3"
                        onChange={(event) => setDetails(event.target.value)}
                      ></textarea>
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Challenge Image</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleImageChange}
                        multiple
                      />
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Add Modal --> */}

        {/* <!-- Edit Modal --> */}
        <div
          className="modal fade"
          id="exampleModaledit"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Challenge
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Challenge Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">No Of Day</label>
                      <input
                        type="number"
                        value={numberOfDays}
                        onChange={(event) =>
                          setNumberOfDays(event.target.value)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Challenge Details</label>
                      <textarea
                        name=""
                        id=""
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        className="form-control"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Challenge Image</label>
                      <div className="d-flex align-items-center">
                        <div className="col">
                          <img
                            src={image}
                            alt="Uploaded Image"
                            className="me-2"
                            style={{ maxWidth: "70px", maxHeight: "70px" }}
                          />
                        </div>
                        <div>
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter image"
                          value={image}
                          style={{ width: "100%" }}
                        /> */}
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Enter image"
                            //onChange={handleImageChange}
                            style={{ width: "100%" }}
                            onChange={(event) =>
                              setImage(event.target.files[0])
                            }
                            //onClick={handleImageChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Edit Modal --> */}

        {<Footer />}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Challenge;
