import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import audio from "../Image/audio.mp3";
import url from "../url";
import axios from "axios";
import { upload } from "@testing-library/user-event/dist/upload";
import Paggination from "../pagination/pagination";
import IsLogin from "./VerifyLogin";

const MusicSound = () => {
  const [music, setMusic] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");

  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [sound, setSound] = useState(null);

  const [ListData, setListData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [nPage, setnPage] = useState([1]);

  const fetchMusicSound = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_music");
      setMusic((prev) => data.data);
      console.log(data);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchMusicData = (item) => {
    console.log(image);
    setType(item?.type);
    setImage(item?.image);
    setName(item?.name);
    setSound(item?.sound);
    setId(item?.id);
    //toast.success(item?.id);

    console.log(item?.id);
  };

  const handleDeleteSound = async (id) => {
    try {
      await axios.post(url() + "/api/user/del_music", { id });
      toast.success("Music Deleted Successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Something Went Wrong!");
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    ///api/user/add_category
    console.log("id", id);
    //toast.success("event id", e.id);
    try {
      console.log(type, image, name, sound);
      let formData = new FormData();
      formData.append("id", id);
      formData.append("type", type);
      formData.append("image", image);
      formData.append("name", name);
      formData.append("sound", sound);
      const { response } = await axios.post(
        url() + `/api/user/upd_music`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Music updated successfully");
      console.log("Music updated successfully");
      console.log(image, formData);
      setisUpdate(!isUpdate);
      console.log(response, id);
    } catch (error) {
      //console.log("Error updating exercise:", error);
      //toast.error("Error Updating Excersize ");
      throw error;
      // Handle error
    }
  };

  // const handleImageAdd = (event) => {
  //   setImage(event.target.files[0]);
  // };
  const insertMusic = async (event) => {
    event.preventDefault();
    try {
      console.log(type, image, name, sound);
      let formData = new FormData();
      formData.append("type", type);
      formData.append("image", image);
      formData.append("name", name);
      formData.append("sound", sound);
      const { data } = await axios.post(
        url() + "/api/user/add_music",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(formData);
      await toast.promise(Promise.resolve(), {
        pending: "Inserting Music...",
        success: "Music Inserted Successfully",
        error: "Failed to insert Music",
      });
      setisUpdate(!isUpdate);
      if (data.success) {
        setisUpdate(!isUpdate);
      }
    } catch (error) {
      toast.error("Failed to insert Music");
      console.error("Error:", error);
    }
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = music.slice(indexOfFirstRecord, indexOfLastRecord);
    setListData(currentRecords);
  };

  IsLogin();
  useEffect(() => {
    fetchMusicSound();
    (async function () {
      try {
        const { data } = await axios.post(url() + "/api/user/get_music");
        const resData = data.data;
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = resData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(resData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  // const handleImageChange = (e) => {
  //   // Set the image when a new file is selected
  //   setImage(URL.createObjectURL(e.target.files[0]));
  //   console.log("url", image);
  // };
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>MUSIC & SOUND</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="mb-3 col-lg-2 col-md-3 col-5">
                    <label className="mb-2">Filter</label>
                    <select
                      className="form-select form-control"
                      id=""
                      onChange={(e) => setFilter(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value="All">All </option>
                      <option value="Music">Music</option>
                      <option value="Sound">Sound</option>
                    </select>
                  </div>
                  <div className="mb-3 pro-add-new px-0">
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#AddMusicSound"
                    >
                      <i className="fa-solid fa-plus"></i> Add Music & Sound
                    </Link>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Music & Sound</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : ListData?.length ? (
                        ListData.filter((item) => {
                          return (
                            (filter === "All" || item.type === filter) && // Apply filter
                            (search.toLowerCase() === ""
                              ? item
                              : item.type.toLowerCase().includes(search) ||
                                item.name.toLowerCase().includes(search))
                          );
                        }).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {currentPage === 1
                                  ? index + 1
                                  : index + 1 + currentPage * 5 - 5}
                              </td>
                              <td>{item.type}</td>
                              <td>{item.name}</td>
                              <td>
                                <div className="profile-img">
                                  <img src={item.image} className="img-fluid" />
                                </div>
                              </td>
                              <td>
                                <audio controls>
                                  {/* <source src="horse.ogg" type="audio/ogg" /> */}
                                  <source src={item.sound} type="audio/mpeg" />
                                </audio>
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#EditMusicSound"
                                    onClick={() => fetchMusicData(item)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteSound(item.id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column mt-2 justify-content-center align-items-center"
                              style={{ height: "15vh" }}
                            >
                              <img src style={{ height: "100px" }} alt="" />
                              <div>no Data found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Tips Modal --> */}
        <div
          className="modal fade"
          id="AddMusicSound"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Music & Sound
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={insertMusic}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Select</label>
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                        onChange={(event) => setType(event.target.value)}
                        //value={type}
                      >
                        <option selected value="All">
                          All
                        </option>
                        <option value="Music">Music</option>
                        <option value="Sound">Sound</option>
                        {/* {music.map(() => {
                          <option key={id} value={id}>
                            {type}
                          </option>;
                        })} */}
                      </select>
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Image</label>
                      <input
                        type="file"
                        onChange={(event) => setImage(event.target.files[0])}
                        className="form-control"
                        multiple
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Name</label>
                      <input
                        type="text"
                        onChange={(event) => setName(event.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload File</label>
                      <input
                        type="file"
                        onChange={(event) => setSound(event.target.files[0])}
                        className="form-control"
                      />
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Add Tips Modal --> */}

        {/* <!-- Edit Modal --> */}
        <div
          className="modal fade"
          id="EditMusicSound"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Music & Sound
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Select</label>
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Floating label select example"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option>All</option>
                        <option value="Music">Music</option>
                        <option value="Sound">Sound</option>
                      </select>
                    </div>
                    <div className="mb-1 col-12">
                      <label className="mb-1">Upload Image</label>
                      {/* <span>{image}</span> */}
                      {/* <input
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])} //(e) => setImage(e.target.files[0])
                        className="form-control"
                        multiple
                      /> */}

                      <br />
                      {/* <input value={image} /> */}
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col">
                        <img
                          src={image}
                          alt="Uploaded Image"
                          className="me-2"
                          style={{ maxWidth: "70px", maxHeight: "70px" }}
                        />
                      </div>
                      <div>
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter image"
                          value={image}
                          style={{ width: "100%" }}
                        /> */}
                        <input
                          type="file"
                          className="form-control"
                          placeholder="Enter image"
                          //onChange={handleImageChange}
                          style={{ width: "100%" }}
                          onChange={(e) => setImage(e.target.files[0])}
                          //onClick={handleImageChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 col-12">
                      <label className="mb-2 mt-3">Name</label>
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload File</label>
                      <input
                        type="file"
                        onChange={(e) => setSound(e.target.files[0])}
                        className="form-control"
                      />
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Edit Modal --> */}

        {<Footer />}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default MusicSound;
