import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import url from "../url";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cookie, setCookie, removeCookie] = useCookies(["id"]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // If already submitting, do nothing

    setIsSubmitting(true); // Set the submitting flag to true

    const toastId = toast.loading("Logging in...");
    try {
      const { data } = await axios.post(
        url() + `/api/user/admin_login`,
        {
          email,
          password,
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(cookie, data, email, password);
      if (data.message === "Login Successful") {
        const { id } = data.Admin;
        if (!id) {
          throw new Error("id is undefined");
        }
        console.log(cookie, id);

        console.log(id);
        //const getId = data.Admin.id;
        // Storing data in localStorage
        // setCookie("token", id, {
        //   path: "/",
        //   expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
        // });

        setCookie("id", id, {
          path: "/",
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
        });
        console.log(cookie);
        // localStorage.setItem("admin_id", getId);

        toast.update(toastId, {
          render: "Login Successfull",
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });

        await new Promise((resolve) => setTimeout(resolve, 1500));
        console.log("Login Successfull", cookie, data, email, password, id);
        navigate("/Dashboard");
      } else {
        throw new Error("Invalid Email Or Password");
      }
    } catch (error) {
      toast.update(toastId, {
        render: "Invalid Email Or Password",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      //toast.error("Invalid Email Or Password");
    } finally {
      setIsSubmitting(false); // Reset the submitting flag
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2>Welcome to Kids Yoga ! 👋</h2>
                  <p>Please sign-in to your account and start the adventure</p>
                </div>
                <div className="col-4">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label for="floatingInput">Email</label>
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label for="floatingPassword">Password</label>
                </div>
                <div className="d-flex justify-content-between align-content-center my-3">
                  <div className="form-check remember-me">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    /> */}
                    {/* <label className="form-check-label" for="gridCheck">
                      Remember me
                    </label> */}
                  </div>
                  {/* <Link to={"EmailVerification"} className="forgot-pass">
                    Forgot Password?
                  </Link> */}
                </div>
                <button
                  type="submit"
                  to={"/Dashboard"}
                  className="btn btn-login"
                >
                  Sign in
                </button>{" "}
                {/* to={"/Dashboard"} */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Signin;
