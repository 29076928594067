import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function IsLogin() {
  const [cookie] = useCookies(["id"]);

  useEffect(() => {
    if (cookie.id === undefined) {
      toast.success("Please login");
      setTimeout(() => {
        window.location = "/";
      }, 500); // Delay of half a second (500 milliseconds)
    }
  }, [cookie.id]);
  <ToastContainer position="top-center" />;
}
