import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import url from "../url";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Paggination from "../pagination/pagination";
import useExercise from "../context/useExcercise";
import useChallenge from "../context/useChallenge";
import IsLogin from "./VerifyLogin";
const Preview = () => {
  //const [isUpdate, setisUpdate] = useState(false);
  const [ListData, setListData] = useState([]);
  const [exc, setExc] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [nPage, setnPage] = useState([1]);
  const [clickedItems, setClickedItems] = useState([]);
  //const [MainData, setMainData] = useState([]);
  const navigate = useNavigate();

  //   const { name } = useParams();
  const location = useLocation();
  const userData = location.state?.userData;
  const { state } = location;
  const { challenge, day: days } = useChallenge();
  const { filterData, MainData, setMainData } = useExercise();
  //const { filter2Data, MainData2 } = useChallenge();

  const fetchExcersize = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_assign");
      setListData(data.data);
      console.log(data);
      setisLoad(true);
    } catch (er) {
      console.log("er", er);
    }
  };

  const handleSubmit = async () => {
    try {
      const level_id = filterData[0]; // Retrieve level_id from filterData
      const ch_id = state?.message1;
      const day_id = days;
      console.log(ch_id);
      //const day_id =
      const updatedMainData = MainData.map((item) => ({
        ...item,
        level_id: level_id,
        ch_id: ch_id,
        day_id: day_id,
      })); // Update MainData with level_id
      const id = updatedMainData.map((item) => item.id);
      console.log(updatedMainData);
      console.log(id);
      const { data } = await axios.post(
        url() + "/api/user/upd_AssignExcersize",
        {
          MainData: updatedMainData,
          level_id: level_id,
          id: id,
          ch_id: ch_id,
          day_id: day_id,
        }
      );
      console.log(data, id, level_id, day_id, ch_id); // Log the response from the server
      toast.success("Exercises Assigned Successfully");
      navigate("/Successfully");
    } catch (error) {
      console.log("Error Assigning Excersizes", error);
      toast.error("Failed to assign excersizes. Please try again later.");
    }
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = ListData.slice(indexOfFirstRecord, indexOfLastRecord);
    setListData(currentRecords);
  };
  IsLogin();
  // const handleDelete = (index) => {
  //   const updatedMainData = [...MainData];
  //   updatedMainData.splice(index, 1); // Remove the item at the specified index
  //   setMainData(updatedMainData); // Update MainData with the modified array
  // };

  const handleDeleteItem = (id) => {
    //alert(index);
    //console.log(index);
    const newArr = MainData?.filter((el) => el?.id != id);
    setMainData(newArr);
  };
  useEffect(() => {
    //fetchLevels();
    setExc((prev) => state?.message);
    fetchExcersize();
    (async function () {
      try {
        const { data } = await axios.post(url() + "/api/user/get_assign");
        const resData = data.data;
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = resData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(resData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
    //fetchChallenge();
    // fetchDays();

    // setTimeout(() => {
    //   // Populate ListData with initialData
    //   setListData();
    //   // Set isLoad to true to indicate data is loaded
    //   setisLoad(true);
    // }, 1000);
    //setExc(data.data);
  }, [isUpdate]);

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                {!!filterData?.length && !challenge && !days && (
                  <>
                    <div className="pro-add-new px-0">
                      <p>LEVELS / CATEGORY</p>
                    </div>
                    <form className="row mb-4">
                      <div className="mb-3 col-lg-2 col-md-3 col-5">
                        <label className="mb-2">Levels / Category</label>
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="Floating label select example"
                          disabled
                        >
                          <option>Select</option>
                          <option selected>{filterData[1]}</option>
                          <option>Sound</option>
                        </select>
                      </div>
                    </form>
                  </>
                )}

                {challenge && days && (
                  <>
                    <div className="pro-add-new px-0">
                      <p>CHALLENGES</p>
                    </div>
                    <form className="row mb-4">
                      <div className="mb-3 col-lg-2 col-md-3 col-5">
                        <label className="mb-2">Challenges</label>
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="Floating label select example"
                          disabled
                        >
                          <option>Select</option>
                          <option selected>{exc}</option>
                          <option>Sound</option>
                        </select>
                      </div>
                      <div className="mb-3 col-lg-2 col-md-3 col-5">
                        <label className="mb-2">Select Day</label>
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="Floating label select example"
                          disabled
                        >
                          <option>Select</option>
                          <option selected>{days}</option>
                          <option>2</option>
                        </select>
                      </div>
                    </form>
                  </>
                )}

                <div className="pro-add-new px-0">
                  <p>PREVIEW</p>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Select</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {MainData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked
                              id={`gridCheck-${index}`}
                            />
                          </td>
                          <td>
                            <div className="profile-img">
                              <img
                                src={item.assign_image}
                                className="img-fluid"
                              />
                            </div>
                          </td>
                          <td>{item.assign_name}</td>
                          <td>
                            <div className="icon-up-del">
                              <Link onClick={() => handleDeleteItem(item?.id)}>
                                <i className="fa-solid fa-trash"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="upload-reset-btn mb-0 pt-3">
                  <Link to={"/AssignExercise"} className="btn btn-reset">
                    BACK
                  </Link>
                  <button
                    to={"/Successfully"}
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-upload me-0"
                  >
                    Submit
                  </button>
                </div>
                {/* <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                /> */}
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Preview;
// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import user from "../Image/user.png";
// import url from "../url";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// import { toast, ToastContainer } from "react-toastify";
// import Paggination from "../pagination/pagination";
// import useExercise from "../context/useExcercise";
// import useChallenge from "../context/useChallenge";
// import IsLogin from "./VerifyLogin";
// const Preview = () => {
//   //const [isUpdate, setisUpdate] = useState(false);
//   const [ListData, setListData] = useState([]);
//   const [exc, setExc] = useState([]);
//   const [isUpdate, setisUpdate] = useState(false);
//   const [isLoad, setisLoad] = useState(false);
//   const [currentPage, setcurrentPage] = useState(1);
//   const [recordsPerPage] = useState(5);
//   const [nPage, setnPage] = useState([1]);
//   const [clickedItems, setClickedItems] = useState([]);
//   //const [MainData, setMainData] = useState([]);
//   const navigate = useNavigate();

//   //   const { name } = useParams();
//   const location = useLocation();
//   const userData = location.state?.userData;
//   const { state } = location;
//   const { challenge, day: days } = useChallenge();
//   const { filterData, MainData, setMainData } = useExercise();
//   //const { filter2Data, MainData2 } = useChallenge();
//   console.log(challenge);
//   console.log(days);
//   console.log(filterData);
//   console.log(MainData);

//   const fetchExcersize = async () => {
//     try {
//       const { data } = await axios.post(url() + "/api/user/get_assign");
//       setListData(data.data);
//       console.log(data);
//       setisLoad(true);
//     } catch (er) {
//       console.log("er", er);
//     }
//   };

//   const handleSubmit = async () => {
//     try {
//       const level_id = filterData[0]; // Retrieve level_id from filterData
//       const ch_id = state?.message1;
//       const day_id = days;
//       console.log(ch_id);
//       //const day_id =
//       const updatedMainData = MainData.map((item) => ({
//         ...item,
//         level_id: item.level_id,
//         ch_id: ch_id,
//         day_id: day_id,
//       })); // Update MainData with level_id
//       const id = updatedMainData.map((item) => item.id);
//       const { data } = await axios.post(
//         url() + "/api/user/upd_AssignExcersize",
//         {
//           MainData: updatedMainData,
//           level_id: level_id,
//           id: id,
//           ch_id: ch_id,
//           day_id: day_id,
//         }
//       );
//       console.log(data, id, level_id, day_id, ch_id); // Log the response from the server
//       toast.success("Exercises Assigned Successfully");
//       navigate("/Successfully");
//     } catch (error) {
//       console.log("Error Assigning Excersizes", error);
//       toast.error("Failed to assign excersizes. Please try again later.");
//     }
//   };

//   const handleChangePage = (id) => {
//     setcurrentPage(id);
//     const indexOfLastRecord = id * recordsPerPage;
//     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//     let currentRecords = ListData.slice(indexOfFirstRecord, indexOfLastRecord);
//     setListData(currentRecords);
//   };
//   IsLogin();
//   // const handleDelete = (index) => {
//   //   const updatedMainData = [...MainData];
//   //   updatedMainData.splice(index, 1); // Remove the item at the specified index
//   //   setMainData(updatedMainData); // Update MainData with the modified array
//   // };

//   const handleDeleteItem = (index) => {
//     //alert(index);
//     //console.log(index);
//     const newArr = MainData?.filter((el) => el?.id != id);
//     setMainData(newArr);
//   };
//   useEffect(() => {
//     //fetchLevels();
//     setExc((prev) => state?.message);
//     fetchExcersize();
//     (async function () {
//       try {
//         const { data } = await axios.post(url() + "/api/user/get_assign");
//         const resData = data.data;
//         let indexOfLastRecord = currentPage * recordsPerPage;
//         let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//         let currentRecords = resData?.slice(
//           indexOfFirstRecord,
//           indexOfLastRecord
//         );
//         setListData((prev) => currentRecords);
//         let nPages = Math.ceil(resData?.length / recordsPerPage);
//         let num = [...Array(nPages + 1).keys()].slice(1);
//         setnPage(num);
//       } catch (er) {
//         console.log(er);
//       } finally {
//         setisLoad(true);
//       }
//     })();
//     //fetchChallenge();
//     // fetchDays();

//     // setTimeout(() => {
//     //   // Populate ListData with initialData
//     //   setListData();
//     //   // Set isLoad to true to indicate data is loaded
//     //   setisLoad(true);
//     // }, 1000);
//     //setExc(data.data);
//   }, [isUpdate]);

//   return (
//     <div>
//       {<Sidebar />}
//       <div className="asside">
//         <div className="about-first">
//           <div className="row">
//             <div className="col-12 mb-24">
//               <div className="bg-box">
//                 {!!filterData?.length && !challenge && !days && (
//                   <>
//                     <div className="pro-add-new px-0">
//                       <p>LEVELS / CATEGORY</p>
//                     </div>
//                     <form className="row mb-4">
//                       <div className="mb-3 col-lg-2 col-md-3 col-5">
//                         <label className="mb-2">Levels / Category</label>
//                         <select
//                           className="form-select form-control"
//                           id="floatingSelectGrid"
//                           aria-label="Floating label select example"
//                           disabled
//                         >
//                           <option>Select</option>
//                           <option selected>{filterData[1]}</option>
//                           <option>Sound</option>
//                         </select>
//                       </div>
//                     </form>
//                   </>
//                 )}

//                 {challenge && days && (
//                   <>
//                     <div className="pro-add-new px-0">
//                       <p>CHALLENGES</p>
//                     </div>
//                     <form className="row mb-4">
//                       <div className="mb-3 col-lg-2 col-md-3 col-5">
//                         <label className="mb-2">Challenges</label>
//                         <select
//                           className="form-select form-control"
//                           id="floatingSelectGrid"
//                           aria-label="Floating label select example"
//                           disabled
//                         >
//                           <option>Select</option>
//                           <option selected>{state?.message}</option>
//                           <option>Sound</option>
//                         </select>
//                       </div>
//                       <div className="mb-3 col-lg-2 col-md-3 col-5">
//                         <label className="mb-2">Select Day</label>
//                         <select
//                           className="form-select form-control"
//                           id="floatingSelectGrid"
//                           aria-label="Floating label select example"
//                           disabled
//                         >
//                           <option>Select</option>
//                           <option selected>{days}</option>
//                           <option>2</option>
//                         </select>
//                       </div>
//                     </form>
//                   </>
//                 )}

//                 <div className="pro-add-new px-0">
//                   <p>PREVIEW</p>
//                 </div>
//                 <div className="responsive-table">
//                   <table className="table">
//                     <thead>
//                       <tr>
//                         <th>ID</th>
//                         <th>Select</th>
//                         <th>Image</th>
//                         <th>Name</th>
//                         <th>Action</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {MainData.map((item, index) => (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id={`gridCheck-${index}`}
//                             />
//                           </td>
//                           <td>
//                             <div className="profile-img">
//                               <img
//                                 src={item.assign_image}
//                                 className="img-fluid"
//                               />
//                             </div>
//                           </td>
//                           <td>{item.assign_name}</td>
//                           <td>
//                             <div className="icon-up-del">
//                               <Link onClick={() => handleDeleteItem(index)}>
//                                 <i className="fa-solid fa-trash"></i>
//                               </Link>
//                             </div>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="upload-reset-btn mb-0 pt-3">
//                   <Link to={"/AssignExercise"} className="btn btn-reset">
//                     BACK
//                   </Link>
//                   <button
//                     to={"/Successfully"}
//                     type="submit"
//                     onClick={handleSubmit}
//                     className="btn btn-upload me-0"
//                   >
//                     Submit
//                   </button>
//                 </div>
//                 {/* <Paggination
//                   nPage={nPage}
//                   currentPage={currentPage}
//                   handleChangePage={handleChangePage}
//                 /> */}
//               </div>
//             </div>
//           </div>
//         </div>
//         {<Footer />}
//       </div>
//       <ToastContainer position="top-center" />
//     </div>
//   );
// };

// export default Preview;

// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import user from "../Image/user.png";
// import url from "../url";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// import { toast, ToastContainer } from "react-toastify";
// import Paggination from "../pagination/pagination";
// import useExercise from "../context/useExcercise";
// import useChallenge from "../context/useChallenge";
// const Preview = () => {
//   //const [isUpdate, setisUpdate] = useState(false);
//   const [ListData, setListData] = useState([]);
//   const [exc, setExc] = useState([]);
//   const [isUpdate, setisUpdate] = useState(false);
//   const [isLoad, setisLoad] = useState(false);
//   const [currentPage, setcurrentPage] = useState(1);
//   const [recordsPerPage] = useState(5);
//   const [nPage, setnPage] = useState([1]);
//   const navigate = useNavigate();

//   //   const { name } = useParams();
//   const location = useLocation();
//   const userData = location.state?.userData;
//   const { state } = location;
//   const { challenge, day: days } = useChallenge();
//   const { filterData, MainData } = useExercise();
//   //const { filter2Data, MainData2 } = useChallenge();
//   console.log(challenge);
//   console.log(days);
//   console.log(filterData);
//   console.log(MainData);

//   const fetchExcersize = async () => {
//     try {
//       const { data } = await axios.post(url() + "/api/user/get_assign");
//       setListData(data.data);
//       console.log(data);
//       setisLoad(true);
//     } catch (er) {
//       console.log("er", er);
//     }
//   };

//   const handleSubmit = async () => {
//     try {
//       const level_id = filterData[0]; // Retrieve level_id from filterData
//       const ch_id = state?.message1;
//       const day_id = days;
//       console.log(ch_id);
//       //const day_id =
//       const updatedMainData = MainData.map((item) => ({
//         ...item,
//         level_id: level_id,
//         ch_id: ch_id,
//         day_id: day_id,
//       })); // Update MainData with level_id
//       const id = updatedMainData.map((item) => item.id);
//       const { data } = await axios.post(
//         url() + "/api/user/upd_AssignExcersize",
//         {
//           MainData: updatedMainData,
//           level_id: level_id,
//           id: id,
//           ch_id: ch_id,
//           day_id: day_id,
//         }
//       );
//       console.log(data, id, level_id, day_id, ch_id); // Log the response from the server
//       toast.success("Exercises Assigned Successfully");
//       navigate("/Successfully");
//     } catch (error) {
//       console.error("Error Assigning Excersizes", error);
//       toast.error("Failed to assign excersizes. Please try again later.");
//     }
//   };

//   const handleChangePage = (id) => {
//     setcurrentPage(id);
//     const indexOfLastRecord = id * recordsPerPage;
//     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//     let currentRecords = ListData.slice(indexOfFirstRecord, indexOfLastRecord);
//     setListData(currentRecords);
//   };
//   useEffect(() => {
//     //fetchLevels();
//     fetchExcersize();
//     (async function () {
//       try {
//         const { data } = await axios.post(url() + "/api/user/get_assign");
//         const resData = data.data;
//         let indexOfLastRecord = currentPage * recordsPerPage;
//         let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//         let currentRecords = resData?.slice(
//           indexOfFirstRecord,
//           indexOfLastRecord
//         );
//         setListData((prev) => currentRecords);
//         let nPages = Math.ceil(resData?.length / recordsPerPage);
//         let num = [...Array(nPages + 1).keys()].slice(1);
//         setnPage(num);
//       } catch (er) {
//         console.log(er);
//       } finally {
//         setisLoad(true);
//       }
//     })();
//     //fetchChallenge();
//     // fetchDays();

//     // setTimeout(() => {
//     //   // Populate ListData with initialData
//     //   setListData();
//     //   // Set isLoad to true to indicate data is loaded
//     //   setisLoad(true);
//     // }, 1000);
//     //setExc(data.data);
//   }, [isUpdate]);
//   return (
//     <div>
//       {<Sidebar />}
//       <div className="asside">
//         <div className="about-first">
//           <div className="row">
//             <div className="col-12 mb-24">
//               <div className="bg-box">
//                 {!!filterData?.length && (
//                   <>
//                     <div className="pro-add-new px-0">
//                       <p>LEVELS / CATEGORY</p>
//                     </div>
//                     <form className="row mb-4">
//                       <div className="mb-3 col-lg-2 col-md-3 col-5">
//                         <label className="mb-2">Levels / Category</label>
//                         <select
//                           className="form-select form-control"
//                           id="floatingSelectGrid"
//                           aria-label="Floating label select example"
//                           disabled
//                         >
//                           <option>Select</option>
//                           <option selected>{filterData[1]}</option>
//                           <option>Sound</option>
//                         </select>
//                       </div>
//                     </form>
//                   </>
//                 )}

//                 {!filterData?.length && challenge && days && (
//                   <>
//                     <div className="pro-add-new px-0">
//                       <p>CHALLENGES</p>
//                     </div>
//                     <form className="row mb-4">
//                       <div className="mb-3 col-lg-2 col-md-3 col-5">
//                         <label className="mb-2">Challenges</label>
//                         <select
//                           className="form-select form-control"
//                           id="floatingSelectGrid"
//                           aria-label="Floating label select example"
//                           disabled
//                         >
//                           <option>Select</option>
//                           <option selected>{state?.message}</option>
//                           <option>Sound</option>
//                         </select>
//                       </div>
//                       <div className="mb-3 col-lg-2 col-md-3 col-5">
//                         <label className="mb-2">Select Day</label>
//                         <select
//                           className="form-select form-control"
//                           id="floatingSelectGrid"
//                           aria-label="Floating label select example"
//                           disabled
//                         >
//                           <option>Select</option>
//                           <option selected>{days}</option>
//                           <option>2</option>
//                         </select>
//                       </div>
//                     </form>
//                   </>
//                 )}

//                 <div className="pro-add-new px-0">
//                   <p>PREVIEW</p>
//                 </div>
//                 <div className="responsive-table">
//                   <table className="table">
//                     <thead>
//                       <tr>
//                         <th>ID</th>
//                         <th>Select</th>
//                         <th>Image</th>
//                         <th>Name</th>
//                         <th>Action</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {MainData.map((item, index) => (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id={`gridCheck-${index}`}
//                             />
//                           </td>
//                           <td>
//                             <div className="profile-img">
//                               <img
//                                 src={item.assign_image}
//                                 className="img-fluid"
//                               />
//                             </div>
//                           </td>
//                           <td>{item.assign_name}</td>
//                           <td>
//                             <div className="icon-up-del">
//                               <Link>
//                                 <i className="fa-solid fa-trash"></i>
//                               </Link>
//                             </div>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="upload-reset-btn mb-0 pt-3">
//                   <Link to={"/AssignExercise"} className="btn btn-reset">
//                     BACK
//                   </Link>
//                   <button
//                     to={"/Successfully"}
//                     type="submit"
//                     onClick={handleSubmit}
//                     className="btn btn-upload me-0"
//                   >
//                     Submit
//                   </button>
//                 </div>
//                 <Paggination
//                   nPage={nPage}
//                   currentPage={currentPage}
//                   handleChangePage={handleChangePage}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         {<Footer />}
//       </div>
//       <ToastContainer position="top-center" />
//     </div>
//   );
// };

// export default Preview;
