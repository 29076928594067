import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../logo.svg'
import success from '../Image/success.gif'

const Successfully = () => {
  return (
    <div>
      <div className='container'>
        <div className='row vh-100 d-flex align-items-center justify-content-center'>
          <div className='col-lg-5 col-md-8 col-12 my-3'>
            <div className="signin-page p-3">
              <form>
                <div className="modal-content">
                  <div className="modal-body text-center pb-3">
                    <div className="p-4">
                      <img src={success} className='img-fluid w-50 mb-2' />
                    </div>
                    <h6>Successfully Submit Assign Exercise...</h6>
                  </div>
                  <div className="modal-footer">
                    <Link to={"/Dashboard"} className="btn btn-login" type='submit'>Go To Dashboard</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Successfully