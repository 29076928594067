import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import url from "../url";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import IsLogin from "./VerifyLogin";

const ChangePassword = ({ id }) => {
  const [current_password, setCurrent_password] = useState();
  const [new_password, setNew_password] = useState();
  const [confirm_password, setConfirm_password] = useState();
  const [cookies, removeCookie] = useCookies(["id"]);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    e.preventDefault();
    try {
      const toastId = toast.loading("Changing password...");
      // Retrieving data from localStorage
      // const admin_id = localStorage.getItem("admin_id");
      //const [cookie] = useCookies(["id"]);
      console.log(cookies); // Output: John
      const { data } = await axios.post(
        url() + `/api/user/change_password`,
        {
          id: cookies.id,
          //token: cookies.token,
          current_password: current_password,
          new_password: new_password,
          confirm_password: confirm_password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data, cookies.id);
      if (data.message === "Password changed successfully") {
        console.log(
          "Password Changed Successfully",
          data,
          cookies.id,
          current_password,
          confirm_password
        );
        toast.update(toastId, {
          render: "Password changed successfully",
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
        //removeCookie("id", { path: "/" });
        //removeCookie("id", { path: "/" });
        setTimeout(() => {
          navigate("/Dashboard");
        }, 2000);
      } else {
        toast.update(toastId, {
          render: "Failed to change password. please Try again",
          type: "error",
          isLoading: false,
          autoClose: 1500,
        });
        //toast.error("Failed to change password. please Try again.", data);
        console.log(data, current_password, confirm_password);
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };
  IsLogin();

  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2 className="mb-0">Change Password</h2>
                </div>
                <div className="col-4">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleChange}>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={current_password}
                    onChange={(e) => setCurrent_password(e.target.value)}
                    required
                  />
                  <label for="floatingInput">Current Password</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={new_password}
                    onChange={(e) => setNew_password(e.target.value)}
                    required
                  />
                  <label for="floatingInput">New Password</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    value={confirm_password}
                    onChange={(e) => setConfirm_password(e.target.value)}
                    required
                  />
                  <label for="floatingPassword">Confirm New Password</label>
                </div>
                <button
                  to={"/Dashboard"}
                  type="submit"
                  className="btn btn-login"
                >
                  Confirm
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ChangePassword;
