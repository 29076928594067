import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import url from "../url";
import axios from "axios";
import isLogin from "./Signin";
import IsLogin from "./VerifyLogin";
import { useCookies } from "react-cookie";
const Dashboard = () => {
  let [exc, setExc] = useState([]);
  let [challenges, setChallenges] = useState([]);
  let [levels, setLevels] = useState([]);
  let [tips, setTips] = useState([]);
  const [music, setMusic] = useState([]);
  let [exc_length, setExc_length] = useState("");
  let [challenge_length, setChallenge_length] = useState("");
  let [levels_length, setLevels_length] = useState("");
  let [tips_length, setTips_length] = useState("");
  const [sound_length, setSoundLength] = useState(0);
  const [music_length, setMusicLength] = useState(0);
  const [cookie] = useCookies(["id"]);
  const toastDisplayed = useRef(false);
  IsLogin();

  useEffect(() => {
    fetchExcersize();
    fetchChallenge();
    fetchLevels();
    fetchTips();
    fetchMusicSound();

    //fetchLevels();
    //setExc(data.data);
  }, []);

  // useEffect(() => {
  //   if (cookie.id === undefined) {
  //     if (!toastDisplayed.current) {
  //       toast.success("Please login");
  //       toastDisplayed.current = true;
  //     }
  //     setTimeout(() => {
  //       window.location = "/";
  //     }, 500); // Delay of half a second (500 milliseconds)
  //   } else {
  //     const interval = setInterval(() => {
  //       //fetchauto();
  //     }, 1000); // Call fetchChallenge every 1 second

  //     return () => clearInterval(interval); // Cleanup the interval on component unmount
  //   }
  // }, [cookie.id]);

  const fetchauto = async () => {};

  const fetchExcersize = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_day_info");

      setExc(data.data);
      console.log(data);
      console.log("Length of array:", data.data.length);
      setExc_length(data.data.length || 0);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchChallenge = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_challenge");
      setChallenges(data.data);
      // Corrected to setChallenges(data)
      console.log(data);
      setChallenge_length(data.data.length || 0);
      console.log(data.data.length);
    } catch (error) {
      console.log("Error fetching challenges:", error);
    }
  };

  const fetchLevels = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_levels");
      setLevels(data.data);
      console.log(data);
      setLevels_length(data.data.length || 0);
      console.log("Length of array:", data.data.length);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchTips = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_tip");
      setTips((prev) => data.data);
      console.log(data);
      setTips_length(data.data.length || 0);
      console.log("Length of array:", data.data.length);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchMusicSound = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_music");
      setMusic((prev) => data.data);
      console.log(data);
      const musicData = data.data.filter((item) => item.type === "Music");
      const soundData = data.data.filter((item) => item.type === "Sound");

      // Set lengths of music and sound arrays
      setMusicLength(musicData.length || 0);
      setSoundLength(soundData.length || 0);

      console.log("Length of music array:", musicData.length);
      console.log("Length of sound array:", soundData.length);
    } catch (er) {
      console.log("er", er);
    }
  };

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
                {/* <Link to={"/NoOfUsersFullSignup"}> */}
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>{challenge_length}</h5>
                      <p>No Of Challenges</p>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
                {/* <Link to={"/NoOfUsersInstantAccess"}> */}
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>{exc_length}</h5>
                      <p>No Of Exercise</p>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
                {/* <Link to={"/TotalNoOfEvents"}> */}
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>{levels_length}</h5>
                      <p>No Of Levels / Category</p>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
                {/* <Link to={"/NoOfMatchesDashboard"}> */}
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>{tips_length}</h5>
                      <p>No Of Tips</p>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
                {/* <Link to={"/NoOfGuestlistSignups"}> */}
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>{music_length}</h5>
                      <p>Music</p>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-24">
                {/* <Link to={"/NoOfVIPTableRequests"}> */}
                <div className="bg-box">
                  <div className="welcome-section">
                    <div className="welcome-text">
                      <h5>{sound_length}</h5>
                      <p>Sound</p>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        {<Footer />}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Dashboard;
