import React, { useState, useEffect } from "react";
// import { Link, useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory,
  useNavigate,
} from "react-router-dom";

import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import url from "../url";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import useExercise from "../context/useExcercise";
import IsLogin from "./VerifyLogin";
import useChallenge from "../context/useChallenge";

const AssignExercise = () => {
  // const { userData } = window.history.state;
  // const history = useHistory();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [ListData, setListData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [LevelData, setLevelData] = useState([]);
  const [SelectExercise, setSelectExercise] = useState([]);
  const [levels, setLevels] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [days, setDays] = useState([]);
  const [challengeName, setChallengeName] = useState();
  const [ch_id, setCh_id] = useState();
  //const [chdays, setChdays] =
  const { setData, clearExercise } = useExercise();
  const {
    setChanllenge: SetChanllenge,
    setDay,
    clearChallenge,
  } = useChallenge();

  const [selected, setSelected] = useState("");

  const [image, setImage] = useState(null);
  const [name, setName] = useState("");

  const handleRadioChange = (event) => {
    clearChallenge();
    clearExercise();
    setSelectedOption(event.target.value);
  };

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleOptionClick = (levelName) => {
    //alert("Option clicked!" + levelName);
    //toast.success(levelName);
    console.log(levelName);
  };

  const handler = async (e) => {
    const level_id = e.target.value;
    const ch_id = e.target.value; // Get the value of the "ch_id" attribute from the selected option
    const day_id = e.target.value;

    let resData;

    // Ensure levels and challenges are properly defined
    if (!levels || !challenges) {
      console.error("Levels or challenges data is not defined");
      return;
    }

    // Find the filter data for levels and challenges
    const filterData = levels.find((el) => el.level_id == level_id);
    //const filterChallengeData = challenges.find((el) => el.ch_id == ch_id);
    //const filterData1 = challenges.find((el) => el.ch_id == ch_id);

    if (!filterData) {
      console.error("No matching level found");
      return;
    }

    console.log(filterData, ch_id);
    console.log(filterData);

    // Construct the new array with the filtered data
    let newArr = [
      filterData?.level_id,
      filterData?.name,
      //level_id,
      //filterData?.ch_id,
      //filterChallengeData?.ch_id,
    ];
    console.log(newArr);
    setLevelData(newArr);
    setSelected(level_id);

    console.log(selected);
    console.log(name);

    try {
      if (level_id === "All") {
        const { data } = await axios.post(url() + "/api/user/get_assign");
        resData = data.data;
        console.log(data, resData);
      } else if (level_id) {
        const { data } = await axios.post(url() + "/api/user/get_assign", {
          level_id,
        });
        console.log(level_id);
        resData = data.data;
        console.log(data.data);
      } else {
        const { data } = await axios.post(url() + "/api/user/get_assign", {
          //level_id,
          ch_id,
          day_id,
        });
        console.log(level_id, day_id, ch_id);
        resData = data.data;
        console.log(data.data);
      }

      if (!resData || resData.length === 0) {
        setListData([]);
      } else {
        setListData(resData);
      }
    } catch (er) {
      console.log("Error:", er);
    }
  };

  const Challengehandler = async (e) => {
    e.preventDefault();
    const ch_id = e.target.value;
    console.log(ch_id);
    // setFilter(category_id);
    //alert("ch_id : " + ch_id);
    SetChanllenge(ch_id);
    try {
      let resData;
      if (ch_id === "All") {
        const { data } = await axios.get(url() + "/api/user/get_challenge");
        resData = data.data;
      } else {
        const [challengeResponse, chDaysResponse] = await Promise.all([
          axios.get(url() + "/api/user/get_challenge", { params: { ch_id } }),
          axios.post(url() + "/api/user/get_chDays", { ch_id }),
        ]);

        const challengeData = challengeResponse.data.data;
        const chDaysData = chDaysResponse.data.data;

        const filteredChallenge = challengeData.find(
          (challenge) => challenge.ch_id === ch_id
        );
        // If a specific challenge is found, extract its name
        const challengeName = filteredChallenge ? filteredChallenge.name : "";
        const chid = filteredChallenge?.ch_id;
        console.log(challengeName);
        if (chid) {
          console.log(`${filteredChallenge.ch_id}`);
        }
        //toast.success(challengeName);
        setChallengeName(challengeName);
        setCh_id(chid);
        //setCh_id(ch_id)
        resData = chDaysData.filter((challenge) => challenge.ch_id === ch_id);
        const newArray = resData.map((item) => item.day_id);
        console.log(newArray);
        setDays(newArray);
        // Update state or perform other actions with newArray
        console.log(challengeData);
        console.log(chDaysData);
        // const { data } = await axios.get(url() + "/api/user/get_challenge", {
        //   ch_id,
        // });
        // resData = data.data;
        // console.log(data.data);
        // const challengeName =
        //   challengeData.length > 0 ? challengeData[0].name : "";
        // console.log("Challenge Name:", challengeName);
      }

      // if (!resData || resData.length === 0) {
      //setListData(resData || []);
      // } else {
      //   setListData(resData);
      // }
    } catch (er) {
      console.log("er", er);
    }
  };

  const handleNextClick = () => {
    let obj = {
      filter: LevelData,
      data: SelectExercise,
      //challengeSelect: challengeName,
    };
    setData(obj);
    console.log(obj, ch_id);
    navigate("/Preview", {
      state: { message: challengeName, message1: ch_id },
    });
  };

  const fetchChallenge = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_challenge");
      setChallenges(data.data);
      console.log(data.data); //setChallengeName();
      // Corrected to setChallenges(data)
      console.log(data);
    } catch (error) {
      console.log("Error fetching challenges:", error);
    }
  };

  const fetchExcersize = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_assign");
      // const renamedData = data.data.map((item) => ({
      //   ExcName: item.name,
      //   ExcImage: item.image,
      //   // Add other properties as needed
      //   ...item,
      // }));
      setListData(data.data);
      console.log(data.data);
      setisLoad(true);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchLevels = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_levels");
      setLevels((prev) => data.data);
      console.log(data);
    } catch (er) {
      console.log("er", er);
    }
  };

  const handleSelectExercise = (check, data) => {
    if (check) {
      setSelectExercise((prev) => [...prev, data]);
    } else {
      const newData = SelectExercise.filter(
        (el) => el.level_id != data.level_id
      );
      setSelectExercise((prev) => newData);
    }
  };

  // const fetchDays = async () => {
  //   try {
  //     const { data } = await axios.post(url() + "/api/user/get_chDays");
  //     setDays(data.data);
  //     console.log(data);
  //   } catch (er) {
  //     console.log("er", er);
  //   }
  // };
  IsLogin();
  useEffect(() => {
    fetchLevels();
    fetchExcersize();
    fetchChallenge();
    // fetchDays();

    // setTimeout(() => {
    //   // Populate ListData with initialData
    //   setListData();
    //   // Set isLoad to true to indicate data is loaded
    //   setisLoad(true);
    // }, 1000);
    //setExc(data.data);
  }, [isUpdate]);
  return (
    <div>
      <Sidebar />
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>ASSIGN EXERCISE</p>
                </div>
                <form className="d-flex mb-4">
                  <div className="form-check me-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="levels"
                      checked={selectedOption === "levels"}
                      onChange={handleRadioChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Levels / Category
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="challenges"
                      checked={selectedOption === "challenges"}
                      onChange={handleRadioChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Challenges
                    </label>
                  </div>
                </form>
                {selectedOption && (
                  <div id="selectone">
                    {/* Content for Levels / Category */}
                    {selectedOption === "levels" && (
                      <div>
                        <div className="pro-add-new px-0">
                          <p>LEVELS / CATEGORY</p>
                        </div>
                        <form className="row mb-4">
                          <div className="mb-3 col-lg-2 col-md-3 col-5">
                            <label className="mb-2">Levels / Category</label>
                            <select
                              className="form-select form-control"
                              id=""
                              aria-label="Floating label select example"
                              onChange={(e) => handler(e)}
                            >
                              <option value="All">All</option>
                              {levels?.map((lvl) => (
                                <option
                                  key={lvl.level_id}
                                  value={lvl.level_id}
                                  // onClick={() => handleOptionClick(lvl.name)}

                                  onClick={() => handleOptionClick(lvl.name)}
                                >
                                  {lvl.name}
                                </option>
                              ))}
                            </select>
                            {/* <select
                              className="form-select form-control"
                              id="floatingSelectGrid"
                              aria-label="Floating label select example"
                            >
                              <option selected>Select</option>
                              <option>Music</option>
                              <option>Sound</option>
                            </select> */}
                          </div>
                        </form>
                      </div>
                    )}

                    {/* Content for Challenges */}
                    {selectedOption === "challenges" && (
                      <div>
                        <div className="pro-add-new px-0">
                          <p>CHALLENGES</p>
                        </div>
                        <form className="row mb-4">
                          <div className="mb-3 col-lg-2 col-md-3 col-5">
                            <label className="mb-2">Challenges</label>
                            <select
                              className="form-select form-control"
                              id="challengeSelect"
                              aria-label="Floating label select example"
                              onChange={(e) => {
                                Challengehandler(e);
                                handler(e);
                              }}
                            >
                              <option value="All">All</option>

                              {challenges?.map((ch) => (
                                <option key={ch.ch_id} value={ch.ch_id}>
                                  {ch.name}
                                </option>
                              ))}
                              {/* {challenges?.map((c) => (
                                <option key={c.ch_id} value={c.ch_id}>
                                  {c.name}
                                </option>
                              ))} */}
                            </select>
                            {/* <select
                              className="form-select form-control"
                              id="floatingSelectGrid"
                              aria-label="Floating label select example"
                            >
                              <option selected>Select</option>
                              <option>Music</option>
                              <option>Sound</option>
                            </select> */}
                          </div>
                          <div className="mb-3 col-lg-2 col-md-3 col-5">
                            <label className="mb-2">Select Day</label>
                            <select
                              className="form-select form-control"
                              id=""
                              aria-label="Floating label select example"
                              onChange={(e) => {
                                setDay(e.currentTarget.value);
                                handler(e);
                              }}
                            >
                              <option value="All">All</option>
                              {days?.map((day) => (
                                <option key={day.day_id} value={day.day_id}>
                                  {day}
                                </option>
                              ))}
                            </select>
                          </div>
                        </form>
                      </div>
                    )}

                    {/* Common content */}
                    <div className="pro-add-new px-0">
                      <p>ALL EXERCISE</p>
                    </div>
                    <div className="responsive-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Select</th>
                            <th>Image</th>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoad ? (
                            <tr>
                              <td colSpan={8}>
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ height: "10vh" }}
                                >
                                  <div className="loader"></div>
                                </div>
                              </td>
                            </tr>
                          ) : ListData?.length ? (
                            ListData.map((item, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="gridCheck"
                                    onChange={(e) =>
                                      handleSelectExercise(
                                        e.target.checked,
                                        item
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="profile-img">
                                    <img
                                      src={item.assign_image}
                                      className="img-fluid"
                                    />
                                  </div>
                                </td>
                                <td>{item.assign_name}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8}>
                                <div
                                  className="d-flex flex-column mt-2 justify-content-center align-items-center"
                                  style={{ height: "15vh" }}
                                >
                                  <img
                                    src=""
                                    style={{ height: "100px" }}
                                    alt=""
                                  />
                                  <div>no Data found !</div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>

                        {/* <tbody>
                          {exc?.map((item, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="gridCheck"
                                />
                              </td>
                              <td>
                                <div className="profile-img">
                                  <img src={item.image} className="img-fluid" />
                                </div>
                              </td>
                              <td>{item.name}</td>
                            </tr>
                          ))}
                        </tbody> */}
                      </table>
                    </div>
                    <div className="upload-reset-btn mb-4 pt-3">
                      <Link to={""} className="btn btn-reset">
                        BACK
                      </Link>

                      <button
                        onClick={handleNextClick}
                        className="btn btn-upload me-0"
                      >
                        NEXT
                      </button>

                      {/* {handleNextClick} */}
                      {/* <Link to={"/Preview"} className="btn btn-upload me-0">
                        NEXT
                      </Link> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default AssignExercise;

// import React, { useState, useEffect } from "react";
// // import { Link, useHistory } from "react-router-dom";
// import {
//   BrowserRouter as Router,
//   Route,
//   Switch,
//   Link,
//   useHistory,
//   useNavigate,
// } from "react-router-dom";

// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import user from "../Image/user.png";
// import url from "../url";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// import { toast, ToastContainer } from "react-toastify";
// import useExercise from "../context/useExcercise";
// import IsLogin from "./VerifyLogin";
// import useChallenge from "../context/useChallenge";

// const AssignExercise = () => {
//   // const { userData } = window.history.state;
//   // const history = useHistory();
//   const navigate = useNavigate();
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [showPreview, setShowPreview] = useState(false);
//   const [isUpdate, setisUpdate] = useState(false);
//   const [ListData, setListData] = useState([]);
//   const [isLoad, setisLoad] = useState(false);
//   const [LevelData, setLevelData] = useState([]);
//   const [SelectExercise, setSelectExercise] = useState([]);
//   const [levels, setLevels] = useState([]);
//   const [challenges, setChallenges] = useState([]);
//   const [days, setDays] = useState([]);
//   const [challengeName, setChallengeName] = useState();
//   const [ch_id, setCh_id] = useState();
//   //const [chdays, setChdays] =
//   const { setData, clearExercise } = useExercise();
//   const {
//     setChanllenge: SetChanllenge,
//     setDay,
//     clearChallenge,
//   } = useChallenge();

//   const [selected, setSelected] = useState("");

//   const [image, setImage] = useState(null);
//   const [name, setName] = useState("");

//   const handleRadioChange = (event) => {
//     clearChallenge();
//     clearExercise();
//     setSelectedOption(event.target.value);
//   };

//   const handleShowPreview = () => {
//     setShowPreview(true);
//   };

//   const handleOptionClick = (levelName) => {
//     //alert("Option clicked!" + levelName);
//     //toast.success(levelName);
//     console.log(levelName);
//   };

//   const handler = async (e) => {
//     const level_id = e.target.value;
//     const ch_id = e.target.value; // Get the value of the "ch_id" attribute from the selected option
//     const day_id = e.target.value;

//     let resData;

//     // Ensure levels and challenges are properly defined
//     if (!levels || !challenges) {
//       console.error("Levels or challenges data is not defined");
//       return;
//     }

//     // Find the filter data for levels and challenges
//     const filterData = levels.find((el) => el.level_id == level_id);
//     //const filterChallengeData = challenges.find((el) => el.ch_id == ch_id);
//     //const filterData1 = challenges.find((el) => el.ch_id == ch_id);

//     if (!filterData) {
//       console.error("No matching level found");
//       return;
//     }

//     console.log(filterData, ch_id);
//     console.log(filterData);

//     // Construct the new array with the filtered data
//     let newArr = [
//       filterData?.level_id,
//       filterData?.name,
//       //level_id,
//       //filterData?.ch_id,
//       //filterChallengeData?.ch_id,
//     ];
//     console.log(newArr);
//     setLevelData(newArr);
//     setSelected(level_id);

//     console.log(selected);
//     console.log(name);

//     try {
//       if (level_id === "All") {
//         const { data } = await axios.post(url() + "/api/user/get_assign");
//         resData = data.data;
//         console.log(data, resData);
//       } else if (level_id) {
//         const { data } = await axios.post(url() + "/api/user/get_assign", {
//           level_id,
//         });
//         console.log(level_id);
//         resData = data.data;
//         console.log(data.data);
//       } else {
//         const { data } = await axios.post(url() + "/api/user/get_assign", {
//           level_id,
//           ch_id,
//           day_id,
//         });
//         console.log(level_id, day_id, ch_id);
//         resData = data.data;
//         console.log(data.data);
//       }

//       if (!resData || resData.length === 0) {
//         setListData([]);
//       } else {
//         setListData(resData);
//       }
//     } catch (er) {
//       console.log("Error:", er);
//     }
//   };

//   const Challengehandler = async (e) => {
//     e.preventDefault();
//     const ch_id = e.target.value;
//     console.log(ch_id);
//     // setFilter(category_id);
//     //alert("ch_id : " + ch_id);
//     SetChanllenge(ch_id);
//     try {
//       let resData;
//       if (ch_id === "All") {
//         const { data } = await axios.get(url() + "/api/user/get_challenge");
//         resData = data.data;
//       } else {
//         const [challengeResponse, chDaysResponse] = await Promise.all([
//           axios.get(url() + "/api/user/get_challenge", { params: { ch_id } }),
//           axios.post(url() + "/api/user/get_chDays", { ch_id }),
//         ]);

//         const challengeData = challengeResponse.data.data;
//         const chDaysData = chDaysResponse.data.data;

//         const filteredChallenge = challengeData.find(
//           (challenge) => challenge.ch_id === ch_id
//         );
//         // If a specific challenge is found, extract its name
//         const challengeName = filteredChallenge ? filteredChallenge.name : "";
//         const chid = filteredChallenge?.ch_id;
//         console.log(challengeName);
//         if (chid) {
//           console.log(`${filteredChallenge.ch_id}`);
//         }
//         //toast.success(challengeName);
//         setChallengeName(challengeName);
//         setCh_id(chid);
//         //setCh_id(ch_id)
//         resData = chDaysData.filter((challenge) => challenge.ch_id === ch_id);
//         const newArray = resData.map((item) => item.day_id);
//         console.log(newArray);
//         setDays(newArray);
//         // Update state or perform other actions with newArray
//         console.log(challengeData);
//         console.log(chDaysData);
//         // const { data } = await axios.get(url() + "/api/user/get_challenge", {
//         //   ch_id,
//         // });
//         // resData = data.data;
//         // console.log(data.data);
//         // const challengeName =
//         //   challengeData.length > 0 ? challengeData[0].name : "";
//         // console.log("Challenge Name:", challengeName);
//       }

//       // if (!resData || resData.length === 0) {
//       //setListData(resData || []);
//       // } else {
//       //   setListData(resData);
//       // }
//     } catch (er) {
//       console.log("er", er);
//     }
//   };

//   const handleNextClick = () => {
//     let obj = {
//       filter: LevelData,
//       data: SelectExercise,
//       //challengeSelect: challengeName,
//     };
//     setData(obj);
//     console.log(obj, ch_id);
//     navigate("/Preview", {
//       state: { message: challengeName, message1: ch_id },
//     });
//   };

//   const fetchChallenge = async () => {
//     try {
//       const { data } = await axios.get(url() + "/api/user/get_challenge");
//       setChallenges(data.data);
//       console.log(data.data); //setChallengeName();
//       // Corrected to setChallenges(data)
//       console.log(data);
//     } catch (error) {
//       console.log("Error fetching challenges:", error);
//     }
//   };

//   const fetchExcersize = async () => {
//     try {
//       const { data } = await axios.post(url() + "/api/user/get_assign");
//       // const renamedData = data.data.map((item) => ({
//       //   ExcName: item.name,
//       //   ExcImage: item.image,
//       //   // Add other properties as needed
//       //   ...item,
//       // }));
//       setListData(data.data);
//       console.log(data.data);
//       setisLoad(true);
//     } catch (er) {
//       console.log("er", er);
//     }
//   };

//   const fetchLevels = async () => {
//     try {
//       const { data } = await axios.get(url() + "/api/user/get_levels");
//       setLevels((prev) => data.data);
//       console.log(data);
//     } catch (er) {
//       console.log("er", er);
//     }
//   };

//   const handleSelectExercise = (check, data) => {
//     if (check) {
//       setSelectExercise((prev) => [...prev, data]);
//     } else {
//       const newData = SelectExercise.filter(
//         (el) => el.level_id != data.level_id
//       );
//       setSelectExercise((prev) => newData);
//     }
//   };

//   // const fetchDays = async () => {
//   //   try {
//   //     const { data } = await axios.post(url() + "/api/user/get_chDays");
//   //     setDays(data.data);
//   //     console.log(data);
//   //   } catch (er) {
//   //     console.log("er", er);
//   //   }
//   // };
//   IsLogin();
//   useEffect(() => {
//     fetchLevels();
//     fetchExcersize();
//     fetchChallenge();
//     // fetchDays();

//     // setTimeout(() => {
//     //   // Populate ListData with initialData
//     //   setListData();
//     //   // Set isLoad to true to indicate data is loaded
//     //   setisLoad(true);
//     // }, 1000);
//     //setExc(data.data);
//   }, [isUpdate]);
//   return (
//     <div>
//       <Sidebar />
//       <div className="asside">
//         <div className="about-first">
//           <div className="row">
//             <div className="col-12 mb-24">
//               <div className="bg-box">
//                 <div className="pro-add-new px-0">
//                   <p>ASSIGN EXERCISE</p>
//                 </div>
//                 <form className="d-flex mb-4">
//                   <div className="form-check me-5">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="flexRadioDefault1"
//                       value="levels"
//                       checked={selectedOption === "levels"}
//                       onChange={handleRadioChange}
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="flexRadioDefault1"
//                     >
//                       Levels / Category
//                     </label>
//                   </div>
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="flexRadioDefault2"
//                       value="challenges"
//                       checked={selectedOption === "challenges"}
//                       onChange={handleRadioChange}
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="flexRadioDefault2"
//                     >
//                       Challenges
//                     </label>
//                   </div>
//                 </form>
//                 {selectedOption && (
//                   <div id="selectone">
//                     {/* Content for Levels / Category */}
//                     {selectedOption === "levels" && (
//                       <div>
//                         <div className="pro-add-new px-0">
//                           <p>LEVELS / CATEGORY</p>
//                         </div>
//                         <form className="row mb-4">
//                           <div className="mb-3 col-lg-2 col-md-3 col-5">
//                             <label className="mb-2">Levels / Category</label>
//                             <select
//                               className="form-select form-control"
//                               id=""
//                               aria-label="Floating label select example"
//                               onChange={(e) => handler(e)}
//                             >
//                               <option value="All">All</option>
//                               {levels?.map((lvl) => (
//                                 <option
//                                   key={lvl.level_id}
//                                   value={lvl.level_id}
//                                   // onClick={() => handleOptionClick(lvl.name)}

//                                   onClick={() => handleOptionClick(lvl.name)}
//                                 >
//                                   {lvl.name}
//                                 </option>
//                               ))}
//                             </select>
//                             {/* <select
//                               className="form-select form-control"
//                               id="floatingSelectGrid"
//                               aria-label="Floating label select example"
//                             >
//                               <option selected>Select</option>
//                               <option>Music</option>
//                               <option>Sound</option>
//                             </select> */}
//                           </div>
//                         </form>
//                       </div>
//                     )}

//                     {/* Content for Challenges */}
//                     {selectedOption === "challenges" && (
//                       <div>
//                         <div className="pro-add-new px-0">
//                           <p>CHALLENGES</p>
//                         </div>
//                         <form className="row mb-4">
//                           <div className="mb-3 col-lg-2 col-md-3 col-5">
//                             <label className="mb-2">Challenges</label>
//                             <select
//                               className="form-select form-control"
//                               id="challengeSelect"
//                               aria-label="Floating label select example"
//                               onChange={(e) => {
//                                 Challengehandler(e);
//                                 handler(e);
//                               }}
//                             >
//                               <option value="All">All</option>

//                               {challenges?.map((ch) => (
//                                 <option key={ch.ch_id} value={ch.ch_id}>
//                                   {ch.name}
//                                 </option>
//                               ))}
//                               {/* {challenges?.map((c) => (
//                                 <option key={c.ch_id} value={c.ch_id}>
//                                   {c.name}
//                                 </option>
//                               ))} */}
//                             </select>
//                             {/* <select
//                               className="form-select form-control"
//                               id="floatingSelectGrid"
//                               aria-label="Floating label select example"
//                             >
//                               <option selected>Select</option>
//                               <option>Music</option>
//                               <option>Sound</option>
//                             </select> */}
//                           </div>
//                           <div className="mb-3 col-lg-2 col-md-3 col-5">
//                             <label className="mb-2">Select Day</label>
//                             <select
//                               className="form-select form-control"
//                               id=""
//                               aria-label="Floating label select example"
//                               onChange={(e) => {
//                                 setDay(e.currentTarget.value);
//                                 handler(e);
//                               }}
//                             >
//                               <option value="All">All</option>
//                               {days?.map((day) => (
//                                 <option key={day.day_id} value={day.day_id}>
//                                   {day}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </form>
//                       </div>
//                     )}

//                     {/* Common content */}
//                     <div className="pro-add-new px-0">
//                       <p>ALL EXERCISE</p>
//                     </div>
//                     <div className="responsive-table">
//                       <table className="table">
//                         <thead>
//                           <tr>
//                             <th>ID</th>
//                             <th>Select</th>
//                             <th>Image</th>
//                             <th>Name</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {!isLoad ? (
//                             <tr>
//                               <td colSpan={8}>
//                                 <div
//                                   className="d-flex justify-content-center align-items-center"
//                                   style={{ height: "10vh" }}
//                                 >
//                                   <div className="loader"></div>
//                                 </div>
//                               </td>
//                             </tr>
//                           ) : ListData?.length ? (
//                             ListData.map((item, index) => (
//                               <tr key={index + 1}>
//                                 <td>{index + 1}</td>
//                                 <td>
//                                   <input
//                                     className="form-check-input"
//                                     type="checkbox"
//                                     id="gridCheck"
//                                     onChange={(e) =>
//                                       handleSelectExercise(
//                                         e.target.checked,
//                                         item
//                                       )
//                                     }
//                                   />
//                                 </td>
//                                 <td>
//                                   <div className="profile-img">
//                                     <img
//                                       src={item.assign_image}
//                                       className="img-fluid"
//                                     />
//                                   </div>
//                                 </td>
//                                 <td>{item.assign_name}</td>
//                               </tr>
//                             ))
//                           ) : (
//                             <tr>
//                               <td colSpan={8}>
//                                 <div
//                                   className="d-flex flex-column mt-2 justify-content-center align-items-center"
//                                   style={{ height: "15vh" }}
//                                 >
//                                   <img
//                                     src=""
//                                     style={{ height: "100px" }}
//                                     alt=""
//                                   />
//                                   <div>no Data found !</div>
//                                 </div>
//                               </td>
//                             </tr>
//                           )}
//                         </tbody>

//                         {/* <tbody>
//                           {exc?.map((item, index) => (
//                             <tr key={index + 1}>
//                               <td>{index + 1}</td>
//                               <td>
//                                 <input
//                                   className="form-check-input"
//                                   type="checkbox"
//                                   id="gridCheck"
//                                 />
//                               </td>
//                               <td>
//                                 <div className="profile-img">
//                                   <img src={item.image} className="img-fluid" />
//                                 </div>
//                               </td>
//                               <td>{item.name}</td>
//                             </tr>
//                           ))}
//                         </tbody> */}
//                       </table>
//                     </div>
//                     <div className="upload-reset-btn mb-4 pt-3">
//                       <Link to={""} className="btn btn-reset">
//                         BACK
//                       </Link>

//                       <button
//                         onClick={handleNextClick}
//                         className="btn btn-upload me-0"
//                       >
//                         NEXT
//                       </button>

//                       {/* {handleNextClick} */}
//                       {/* <Link to={"/Preview"} className="btn btn-upload me-0">
//                         NEXT
//                       </Link> */}
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />
//       </div>
//       <ToastContainer position="top-center" />
//     </div>
//   );
// };

// export default AssignExercise;
