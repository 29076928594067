import { create } from "zustand";

const useChallenge = create((set) => ({
  challenge: null,
  day: null,

  setChanllenge: (item) => set((state) => ({ challenge: item })),
  //setChanllengeName(item) => set
  setDay: (item) => set((state) => ({ day: item })),

  clearChallenge: () => set((state) => ({ challenge: null, day: null })),
}));

export default useChallenge;

// import { create } from "zustand";

// const useChallenge = create((set) => ({
//   challenge: null,
//   day: null,
//   setChanllenge: (item) => set((state) => ({ challenge: item })),
//   //setChanllengeName(item) => set
//   setDay: (item) => set((state) => ({ day: item })),
// }));

// export default useChallenge;
