import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import LevelsCategory from "./components/LevelsCategory";
import Challenge from "./components/Challenge";
import AssignExercise from "./components/AssignExercise";
import Preview from "./components/Preview";
import Exercise from "./components/Exercise";
import MusicSound from "./components/MusicSound";
import Tips from "./components/Tips";
import Scrolltotop from "./Scrolltotop";
import Signin from "./components/Signin";
import ForgotPassword from "./components/ForgotPassword";
import Successfully from "./components/Successfully";
import ChangePassword from "./components/ChangePassword";
import updateExcersize from "./components/updateExcersize";

function App() {
  return (
    <div className="App">
      <Router>
        <Scrolltotop />
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/LevelsCategory" element={<LevelsCategory />} />
          <Route path="/Challenge" element={<Challenge />} />
          <Route path="/AssignExercise" element={<AssignExercise />} />
          <Route path="/Preview" element={<Preview />} />
          <Route path="/Exercise" element={<Exercise />} />
          {/* <Route path="/UpdExcersize/:id" element={<updateExcersize />} /> */}

          <Route path="/MusicSound" element={<MusicSound />} />
          <Route path="/Tips" element={<Tips />} />
          <Route path="/Successfully" element={<Successfully />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
