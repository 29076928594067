import React from "react";
import { Link } from "react-router-dom";

const Paggination = ({ currentPage, nPage, handleChangePage = () => {} }) => {
  return (
    <div className="pro-add-new px-0 mb-0 pt-3">
      <p>
        1 - {nPage?.length} of {currentPage}
      </p>
      <nav aria-label="...">
        <ul className="pagination pagination-sm mb-0">
          {nPage?.map((item) => {
            return (
              <Link
                className={`page-item ${currentPage == item && "active"} `}
                style={{ cursor: "pointer" }}
                aria-current="page"
                onClick={() => handleChangePage(item)}
              >
                <span className="page-link">{item}</span>
              </Link>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Paggination;
