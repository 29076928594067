import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Paggination from "../pagination/pagination";
//import user from "../Image/user.png";
import url from "../url";
import axios from "axios";
import IsLogin from "./VerifyLogin";

const Exercise = () => {
  let [exc, setExc] = useState([]);
  let [levels, setLevels] = useState([]);
  const [search, setSearch] = useState("");
  const [isUpdate, setisUpdate] = useState(false);
  const [id, setId] = useState("");
  const [level_id, setLevel_id] = useState("");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");

  const [ListData, setListData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [nPage, setnPage] = useState([1]);

  const fetchExerciseData = (item) => {
    setName(item?.name);
    setImage(item?.image);
    setDetails(item?.details);
    setId(item?.id);
    //toast.success(item?.id);

    console.log(item?.id);
  };
  IsLogin();
  useEffect(() => {
    fetchExerciseData();
  }, [isUpdate]);

  // No need to set exc here, as it's derived from the main exc state

  const handleImageUpd = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("event id", id);
    //toast.success("event id", e.id);
    try {
      const formDataSend = new FormData();
      formDataSend.append("id", id);
      formDataSend.append("image", image);
      formDataSend.append("name", name);
      formDataSend.append("details", details);
      const { response } = await axios.post(
        url() + `/api/user/upd_exc`,
        formDataSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Excersize updated successfully");
      console.log("Excersize updated successfully");
      setisUpdate(!isUpdate);
      // Redirect or handle success
    } catch (error) {
      console.log("Error updating exercise:", error);
      toast.error("Error Updating Excersize ");
      if (error.response) {
        console.log("Response data:", error.response.data);
      }
      // Handle error
    }
  };
  //let ApiPath = ;
  const fetchExcersize = async () => {
    try {
      const { data } = await axios.post(url() + "/api/user/get_day_info");
      setExc(data.data);
      console.log(data);
      setisUpdate(true);
    } catch (er) {
      console.log("er", er);
    }
  };

  const fetchLevels = async () => {
    try {
      const { data } = await axios.get(url() + "/api/user/get_levels");
      setLevels((prev) => data.data);
      console.log(data);
    } catch (er) {
      console.log("er", er);
    }
  };

  const handleDeleteData = async (id) => {
    try {
      await axios.post(url() + "/api/user/del_exc", { id });
      toast.success("Exercise Deleted Successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Something Went Wrong!");
      throw error;
    }
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = exc.slice(indexOfFirstRecord, indexOfLastRecord);
    setListData(currentRecords);
  };
  useEffect(() => {
    fetchExcersize();
    fetchLevels();
    (async function () {
      try {
        const { data } = await axios.post(url() + "/api/user/get_day_info");
        const resData = data.data;
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = resData?.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setListData((prev) => currentRecords);
        let nPages = Math.ceil(resData?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
    //setExc(data.data);
  }, [isUpdate]);
  const handler = async (e) => {
    const level_id = e.target.value;
    //setFilter(category_id);
    //alert("Category ID: " + category_id);
    try {
      let resData;
      if (level_id === "All") {
        const { data } = await axios.post(url() + "/api/user/get_day_info");
        resData = data.data;
      } else {
        const { data } = await axios.post(url() + "/api/user/get_day_info", {
          level_id,
        });
        resData = data.data;
      }

      if (!resData || resData.length === 0) {
        setListData([]);
      } else {
        setListData(resData);
      }
    } catch (er) {
      console.log("er", er);
    }
  };

  const insertExcersize = async (event) => {
    event.preventDefault();
    try {
      console.log(image, name, details);
      let formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("details", details);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      };
      const { data } = await axios.post(
        url() + "/api/user/add_exersize",
        formData,
        config
      );
      console.log(data);
      toast.success("Excersize Added successfully");
      console.log("Excersize Added successfully");
      setisUpdate(!isUpdate);
    } catch (error) {
      toast.error("Failed to insert Exercise");
      console.error("Error:", error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div>
      <Sidebar />
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12 mb-24">
              <div className="bg-box">
                <div className="pro-add-new px-0">
                  <p>EXERCISE</p>
                  <div className="form-floating small-floating">
                    <input
                      type="text"
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control py-2 w-auto"
                      id="floatingInput"
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput">Search</label>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="mb-3 col-lg-2 col-md-3 col-5">
                    <label className="mb-2">Filter</label>
                    <select
                      className="form-select form-control"
                      id=""
                      aria-label="Floating label select example"
                      onChange={(e) => handler(e)}
                    >
                      <option value="All">All </option>
                      {levels?.map((lvl) => (
                        <option key={lvl.level_id} value={lvl.level_id}>
                          {lvl.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 pro-add-new px-0">
                    <Link
                      type="button"
                      className="btn add-new"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAdd"
                    >
                      <i className="fa-solid fa-plus"></i> Add Exercise
                    </Link>
                  </div>
                </div>
                <div className="responsive-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Select</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Levels</th>
                        <th>Details</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoad ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "10vh" }}
                            >
                              <div class="loader"></div>
                            </div>
                          </td>
                        </tr>
                      ) : ListData?.length ? (
                        ListData.filter((item) => {
                          return search.toLowerCase() === ""
                            ? true
                            : item.name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                        }).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {currentPage === 1
                                  ? index + 1
                                  : index + 1 + currentPage * 5 - 5}
                              </td>
                              <td>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="gridCheck"
                                />
                              </td>
                              <td>
                                <div className="profile-img">
                                  <img src={item.image} className="img-fluid" />
                                </div>
                              </td>
                              <td>{item.name}</td>
                              <td>
                                {Array.isArray(item.level_id) &&
                                item.level_id.length > 1
                                  ? item.level_id.join(", ")
                                  : item.level_id}
                              </td>
                              <td>{item.details}</td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModaledit"
                                    onClick={() => fetchExerciseData(item)}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                  </Link>
                                  <Link
                                    onClick={() => handleDeleteData(item.id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div
                              className="d-flex flex-column mt-2 justify-content-center align-items-center"
                              style={{ height: "15vh" }}
                            >
                              <img src style={{ height: "100px" }} alt="" />
                              <div>no Data found !</div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {/* <tbody>
                      {exc
                        ?.filter((item) => {
                          return search.toLowerCase() === ""
                            ? item
                            : item.name.toLowerCase().includes(search);
                        })
                        .map((item, index) => (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck"
                              />
                            </td>
                            <td>
                              <div className="profile-img">
                                <img src={item.image} className="img-fluid" />
                              </div>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.level_id}</td>
                            <td>{item.details}</td>
                            {/* <td>
                                                        <Link to={'#'} class="badge bg-success me-1 text-white">Active</Link>
                                                        <Link to={'#'} class="badge bg-warning me-1 text-white">Inactive</Link>
                                                    </td> */}
                    {/* <td>
                              <div className="icon-up-del">
                                <Link
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModaledit"
                                  //to="/:id" \\  onClick={() => fetchExerciseData(item.id)}
                                  onClick={() => fetchExerciseData(item)}
                                >
                                  <i className="fa-solid fa-pen"></i>
                                </Link>
                                <Link onClick={() => handleDeleteData(item.id)}>
                                  <i className="fa-solid fa-trash"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody> */}{" "}
                    {/* */}
                  </table>
                </div>
                {/* <div className="pro-add-new px-0 mb-0 pt-3">
                  <p>1 - 6 of 6</p>
                  <nav aria-label="...">
                    <ul className="pagination pagination-sm mb-0">
                      <li className="page-item active" aria-current="page">
                        <span className="page-link">1</span>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
                <Paggination
                  nPage={nPage}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Modal --> */}
        <div
          className="modal fade"
          id="exampleModalAdd"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Exercise
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={insertExcersize}>
                    {/* <div className="mb-3 col-12">
                                            <label className='mb-2'>Service Type</label>
                                            <select className="form-select form-control" id="floatingSelectGrid" aria-label="Floating label select example">
                                                <option selected>Ride</option>
                                                <option value="1">Courier</option>
                                            </select>
                                        </div> */}
                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Exercise Image</label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        className="form-control"
                        multiple
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Exercise Name</label>
                      <input
                        type="text"
                        onChange={(event) => setName(event.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Exercise Details</label>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        cols="30"
                        rows="3"
                        onChange={(event) => setDetails(event.target.value)}
                      ></textarea>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        type="submit"
                        data-bs-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Add Modal --> */}

        {/* <!-- Edit Modal --> */}
        <div
          className="modal fade"
          id="exampleModaledit"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Exercise
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="user-details">
                  <form className="row" onSubmit={handleSubmit}>
                    {/* <div className="mb-3 col-12"> 
                                            <label className='mb-2'>Service Type</label>
                                            <select className="form-select form-control" id="floatingSelectGrid" aria-label="Floating label select example">
                                                <option selected>Ride</option>
                                                <option value="1">Courier</option>
                                            </select>
                                        </div> */}

                    <div className="mb-3 col-12">
                      <label className="mb-2">Upload Exercise Image</label>
                      <div className="d-flex align-items-center">
                        <div className="col">
                          <img
                            src={image}
                            alt="Uploaded Image"
                            className="me-2"
                            style={{ maxWidth: "70px", maxHeight: "70px" }}
                          />
                        </div>
                        <div>
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter image"
                          value={image}
                          style={{ width: "100%" }}
                        /> */}
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Enter image"
                            //onChange={handleImageChange}
                            style={{ width: "100%" }}
                            onChange={(e) => setImage(e.target.files[0])}
                            //onClick={handleImageChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 col-12">
                      <label className="mb-2">Exercise Name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="mb-2">Exercise Details</label>
                      <textarea
                        name="details"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                        id=""
                        className="form-control"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                      <button className="btn btn-reset" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button
                        className="btn btn-upload me-0"
                        data-bs-dismiss="modal"
                        type="submit" // Add this attribute
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Edit Modal --> */}

        {<Footer />}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Exercise;
